import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import useWindowDimension from '../Hook/screenDimention'
import Hamburger from './hamburger'
import { Button } from 'antd'



function Nav() {
  const [hamburgerOpen, setHambuegerOpen] = useState(false);
  // const { width } = useWindowDimension()
  const toggleHamburger = () => {
    setHambuegerOpen(!hamburgerOpen)
  }

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714309/birdies/logo_pq3yrh.png" alt='birdies logo' className='navlogo' />
        <ul class="list_style lg"
        //  style={{display: `${hamburgerOpen ? 'inline' : width > 768 ? 'flex' : 'none'}`}}
        >
          <li className='listholders'>
            <Link to="/" style={{color: window.location.pathname.includes('') ? '#15518A' : '#000000'}} className='listItemH'>Home</Link>
          </li>
          <li className='listholders'>
            <Link  to="/about" style={{color: window.location.pathname.includes('/about') ? '#15518A' : '#000000'}} className="listItem">About Us
            </Link>
          </li>
          <li className='listholders'>
            <Link to="/storeLocator" style={{color: window.location.pathname.includes('/storeLocator') ? '#15518A' : '#000000'}} className="listItem">Store locator</Link>
          </li>
          <li className='listholders'>
            <Link to="/careers" style={{color: window.location.pathname.includes('/careers') ? '#15518A' : '#000000'}} className="listItem">Careers</Link>
          </li>
          <Button className='butholders'>
            <Link to="login" className='listInBut'>Login</Link>
          </Button>
        </ul>
        <div className='hamburgerN' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
      </div>
      <ul className={`list_style_sm ${hamburgerOpen ? 'active' : ''}`}
        // style={{ display: `${hamburgerOpen ? 'flex' : 'none'}` }}
        >
        <li className='listholders'>
          <Link to="/" className='listItem'>Home</Link>
        </li>
        <li className='listholders'>
          <Link to="/about" className="listItem">About Us
          </Link>
        </li>
        <li className='listholders'>
          <Link to="/storeLocator" className="listItem">Store locator</Link>
        </li>
        <li className='listholders'>
          <Link to="/careers" className="listItem">Careers</Link>
        </li>
        <Button className='butholders '>
          <Link to="login" className='listInBut'>Login</Link>
        </Button>
      </ul>
    </nav>
  )
}

export default Nav


// @media (max-width: 768px) {
//   @content ;
// }